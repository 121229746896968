import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import StickyEnquiryCta from '~2-components/StickyEnquiryCta/StickyEnquiryCta';

const stickyEnquiryCtaEg = `import StickyEnquiryCta from '~2-components/StickyEnquiryCta/StickyEnquiryCta';

<StickyEnquiryCta />
`;

const SgStickyEnquiryCta = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Sticky Enquiry Cta</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={stickyEnquiryCtaEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <StickyEnquiryCta/>
        </SgLayout>
    );
};

export default SgStickyEnquiryCta;

